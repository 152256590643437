module.exports = [{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"altInventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":["03312022"]},
    },{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"inventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":["221478"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9a276c068dcb3167cd69795d814d87fb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
